<template>
  <div class="dizme_tm_section" id="service">
    <div class="dizme_tm_services">
      <div class="container">
        <div class="dizme_tm_main_title" data-align="center">
          <span>Services</span>
          <h3>What I Do for Clients</h3>
          <p>
            Designing & Developing websites that work well on desktop is
            responsive, adaptive design and good functionalities
          </p>
        </div>
        <div class="service_list">
          <ul>
            <li
              class="wow fadeInLeft"
              data-wow-duration="1s"
              @click.prevent="active = 1"
            >
              <div class="list_inner tilt-effect">
                <span class="icon">
                  <img class="svg" src="/img/slider/documents.png" alt="" />
                  <img
                    class="back"
                    :src="`/img/brushes/service${dark ? '/dark' : ''}/1.png`"
                    alt=""
                  />
                </span>
                <div class="title">
                  <h3>Documentation</h3>
                  <span class="price">Including <span>Models</span></span>
                </div>
                <div class="text">
                  <p>
                    The software project documentation provides a concise guide
                    to its architecture and functionalities, featuring models
                    for data flow, process interactions, and structural
                    components.
                  </p>
                </div>
                <a class="dizme_tm_full_link" href="#"></a>
                <img
                  class="popup_service_image"
                  src="/img/service/1.jpg"
                  alt=""
                />
              </div>
            </li>
            <li
              class="wow fadeInRight"
              data-wow-duration="1s"
              data-wow-delay="0.2s"
              @click.prevent="active = 2"
            >
              <div class="list_inner tilt-effect">
                <span class="icon">
                  <img class="svg" src="/img/svg/service/physics.svg" alt="" />
                  <img
                    class="back"
                    :src="`/img/brushes/service${dark ? '/dark' : ''}/2.png`"
                    alt=""
                  />
                </span>
                <div class="title">
                  <h3>Full-Stack Development</h3>
                  <span class="price">Development & <span>Testing</span></span>
                </div>
                <div class="text">
                  <p>
                    Development documentation guides software creation with
                    coding practices and milestones, while testing documentation
                    ensures quality assurance through comprehensive strategies.
                  </p>
                </div>
              </div>
            </li>
            <li
              class="wow fadeInLeft"
              data-wow-duration="1s"
              @click.prevent="active = 3"
            >
              <div class="list_inner tilt-effect">
                <span class="icon">
                  <img class="svg" src="/img/svg/service/contact.svg" alt="" />
                  <img
                    class="back"
                    :src="`/img/brushes/service${dark ? '/dark' : ''}/3.png`"
                    alt=""
                  />
                </span>
                <div class="title">
                  <h3>UI/UX Design</h3>
                  <span class="price"
                    >Responsiveness & <span>Adaptive</span></span
                  >
                </div>
                <div class="text">
                  <p>
                    Revolutionize interactions with our UI/UX design service,
                    crafting visually interfaces for seamless navigation.
                    Elevate your digital presence with a blend of aesthetics,
                    ensuring a experience.
                  </p>
                </div>
               
              </div>
            </li>
            <li
              class="wow fadeInRight"
              data-wow-duration="1s"
              data-wow-delay="0.2s"
              @click.prevent="active = 4"
            >
              <div class="list_inner tilt-effect">
                <span class="icon">
                  <img class="svg" src="/img/service/operations.png" alt="" />
                  <img
                    class="back"
                    :src="`/img/brushes/service${dark ? '/dark' : ''}/4.png`"
                    alt=""
                  />
                </span>
                <div class="title">
                  <h3>DevOps</h3>
                  <span class="price">Testing & <span>Deployment</span></span>
                </div>
                <div class="text">
                  <p>
                    Empowering your development journey with our expert DevOps
                    services, we optimize collaboration, streamline workflows,
                    and accelerate software delivery through seamless
                    integration and automation.
                  </p>
                </div>

              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="brush_1 wow fadeInLeft" data-wow-duration="1s">
        <img src="/img/brushes/service/5.png" alt="" />
      </div>
      <div class="brush_2 wow zoomIn" data-wow-duration="1s">
        <img src="/img/brushes/service/6.png" alt="" />
      </div>
    </div>
  </div>
  <div :class="active == 1 ? '' : 'service_hidden_details'">
    <ModalBox :close="close">
      <div class="service_popup_informations">
        <div class="image">
          <img src="img/thumbs/4-2.jpg" alt="" />
          <div
            class="main"
            data-img-url="img/service/document.jpg"
            style="background-image: url('img/service/document.jpg')"
          ></div>
        </div>
        <div class="main_title">
          <h3>Documentation</h3>
          <span class="price">Including Models and Diagrams</span>
        </div>
        <div class="descriptions">
          <p>
            The software project documentation serves as a succinct guide,
            offering valuable insights into the architecture and functionalities
            of the system. It includes comprehensive models illustrating data
            flow, process interactions, and structural components. This
            documentation plays a crucial role in aiding developers and
            stakeholders by providing a clear and organized overview of the
            software's design and operation.
          </p>
          <p>
            In essence, the software project documentation is a concise guide
            outlining the system's architecture and functionalities. It includes
            models depicting data flow, process interactions, and structural
            components, serving as a crucial reference for developers and
            stakeholders. This documentation facilitates effective
            collaboration, aids in project planning, and ensures continuity and
            ease of maintenance throughout the software development life cycle.
          </p>
        </div>
      </div>
    </ModalBox>
  </div>
  <div :class="active == 2 ? '' : 'service_hidden_details'">
    <ModalBox :close="close">
      <div class="service_popup_informations">
        <div class="image">
          <img src="img/thumbs/4-2.jpg" alt="" />
          <div
            class="main"
            data-img-url="img/service/web.jpg"
            style="background-image: url('img/service/web.jpg')"
          ></div>
        </div>
        <div class="main_title">
          <h3>Full Stack Web Development</h3>
          <span class="price">Development & <span>Testing</span></span>
        </div>
        <div class="descriptions">
          <p>
            Development documentation plays a pivotal role in guiding the
            software creation process by outlining coding practices and defining
            milestones. It serves as a roadmap for developers, providing a
            structured approach to the implementation of features and
            functionalities.
          </p>
          <p>
            On the other hand, testing documentation is essential for quality
            assurance, employing comprehensive strategies to validate the
            software's functionality, performance, and reliability. Together,
            these documentation practices contribute to the overall success and
            effectiveness of the software development process.
          </p>
        </div>
      </div>
    </ModalBox>
  </div>
  <div :class="active == 3 ? '' : 'service_hidden_details'">
    <ModalBox :close="close">
      <div class="service_popup_informations">
        <div class="image">
          <img src="img/thumbs/4-2.jpg" alt="" />
          <div
            class="main"
            data-img-url="img/service/ui.jpg"
            style="background-image: url('img/service/ui.jpg')"
          ></div>
        </div>
        <div class="main_title">
          <h3>UI & UX</h3>
          <span class="price"><span>Responsiveness & </span>Adaptive</span>
        </div>
        <div class="descriptions">
          <p>
            Transform your user interactions with our cutting-edge UI/UX design
            service, where we specialize in creating visually captivating
            interfaces for seamless navigation. Propel your digital presence to
            new heights by combining aesthetics and functionality, ensuring an
            exceptional user experience. Let our design expertise redefine the
            way users engage with your platform, offering a blend of innovation
            and user-centric design that sets you apart in the digital
            landscape.
          </p>

          <p>
            Our UI/UX design service is geared towards revolutionizing digital
            experiences. We go beyond aesthetics, focusing on crafting
            interfaces that not only visually captivate but also ensure
            intuitive navigation. Elevate your brand's online presence by
            partnering with us to create a harmonious blend of design and
            functionality. Whether you're launching a new product or enhancing
            an existing platform, our commitment is to deliver an unparalleled
            user experience that resonates with your audience and leaves a
            lasting impression. Embrace innovation and user-centric design to
            stand out in today's competitive digital realm.
          </p>
        </div>
      </div>
    </ModalBox>
  </div>
  <div :class="active == 4 ? '' : 'service_hidden_details'">
    <ModalBox :close="close">
      <div class="service_popup_informations">
        <div class="image">
          <img src="img/thumbs/4-2.jpg" alt="" />
          <div
            class="main"
            data-img-url="img/service/dev.jpg"
            style="background-image: url('img/service/dev.jpg')"
          ></div>
        </div>
        <div class="main_title">
          <h3>DevOps</h3>
          <span class="price">Testing & <span>Deployment</span></span>
        </div>
        <div class="descriptions">
          <p>
            Embrace efficiency and reliability in your software development
            lifecycle with our DevOps solutions. Our integrated approach
            seamlessly merges development and operations, fostering
            collaboration and automating processes to accelerate your product
            delivery. From continuous integration and automated testing to
            deployment and monitoring, our DevOps practices ensure a streamlined
            and agile development pipeline.
          </p>

          <p>
            Experience increased collaboration, faster time-to-market, and
            improved overall quality by integrating our DevOps expertise into
            your development workflows. Let us empower your team to navigate the
            complexities of modern software development with efficiency and
            precision.
          </p>
        </div>
      </div>
    </ModalBox>
  </div>
</template>

<script>
import ModalBox from "./popup/ModalBox.vue";
export default {
  name: "ServiceCompoent",
  data() {
    return {
      active: 0,
    };
  },
  mounted() {
    let VanillaTilt = require("vanilla-tilt");
    VanillaTilt.init(document.querySelectorAll(".tilt-effect"), {
      maxTilt: 6,
      easing: "cubic-bezier(.03,.98,.52,.99)",
      speed: 500,
      transition: true,
    });
  },
  methods: {
    close() {
      this.active = 0;
    },
  },
  components: { ModalBox },
  props: {
    dark: { type: Boolean },
  },
};
</script>
