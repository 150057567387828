<template>
  <div class="dizme_tm_section" id="portfolio">
    <div class="dizme_tm_portfolio">
      <div class="container">
        <div class="dizme_tm_main_title" data-align="center">
          <span>Portfolio</span>
          <h3>My Amazing Works</h3>
          <p>
            Most common methods for designing & developing websites that work
            well on desktop has responsiveness and adaptive design
          </p>
        </div>
        <div class="portfolio_filter">
          <ul>
            <li>
              <a
                class="c-pointer"
                :class="activeNav === '6' ? 'current' : ''"
                @click.prevent="() => (this.activeNav = '1')"
                data-filter=".detail"
                >Detail</a
              >
            </li>
          </ul>
        </div>
        <div class="dizme_tm_portfolio_titles"></div>
        <div class="portfolio_list wow fadeInUp" data-wow-duration="1s">
          <ul class="gallery_zoom grid">
            <li class="detail grid-item">
              <div class="inner">
                <div
                  class="entry dizme_tm_portfolio_animation_wrap"
                  data-title="Bitlogicx"
                  data-category="Webiste"
                >
                  <a
                    class="portfolio_popup"
                    href="#"
                    @click.prevent="active = 1"
                  >
                    <img src="/img/portfolio/bit2.png" alt="" />
                    <div
                      class="main"
                      data-img-url="/img/portfolio/bit2.png"
                    ></div>
                  </a>
                </div>
                <div class="mobile_title">
                  <h3>Bitlogicx</h3>
                  <span>Website</span>
                </div>
              </div>
            </li>
            <li class="detail grid-item">
              <div class="inner">
                <div
                  class="entry dizme_tm_portfolio_animation_wrap"
                  data-title="SMP"
                  data-category="Product"
                >
                  <a
                    class="portfolio_popup"
                    href="#"
                    @click.prevent="active = 2"
                  >
                    <img src="/img/portfolio/smp1.png" alt="" />
                    <div
                      class="main"
                      data-img-url="/img/portfolio/smp1.png"
                    ></div>
                  </a>
                </div>
                <div class="mobile_title">
                  <h3>SMP</h3>
                  <span>Product</span>
                </div>
              </div>
            </li>
            <li class="detail grid-item">
              <div class="inner">
                <div
                  class="entry dizme_tm_portfolio_animation_wrap"
                  data-title="IBN FIRNIS"
                  data-category="Website"
                >
                  <a
                    class="portfolio_popup"
                    href="#"
                    @click.prevent="active = 3"
                  >
                    <img src="/img/portfolio/ibn1.png" alt="" />
                    <div
                      class="main"
                      data-img-url="/img/portfolio/ibn1.png"
                    ></div>
                  </a>
                </div>
                <div class="mobile_title">
                  <h3>IBN FIRNIS</h3>
                  <span>Website</span>
                </div>
              </div>
            </li>
            <li class="detail grid-item">
              <div class="inner">
                <div
                  class="entry dizme_tm_portfolio_animation_wrap"
                  data-title="Eyes Of Hope"
                  data-category="Medical Service"
                >
                  <a
                    class="portfolio_popup"
                    href="#"
                    @click.prevent="active = 4"
                  >
                    <img src="/img/portfolio/eyes1.png" alt="" />
                    <div
                      class="main"
                      data-img-url="/img/portfolio/eyes1.png"
                    ></div>
                  </a>
                </div>
                <div class="mobile_title">
                  <h3>Eyes Of Hope</h3>
                  <span>Medical Service</span>
                </div>
              </div>
            </li>
            <li class="detail grid-item">
              <div class="inner">
                <div
                  class="entry dizme_tm_portfolio_animation_wrap"
                  data-title="Battery Management"
                  data-category="Management System"
                >
                  <a
                    class="portfolio_popup"
                    href="#"
                    @click.prevent="active = 5"
                  >
                    <img src="/img/portfolio/battery1.png" alt="" />
                    <div
                      class="main"
                      data-img-url="/img/portfolio/battery1.png"
                    ></div>
                  </a>
                </div>
                <div class="mobile_title">
                  <h3>Battery Management</h3>
                  <span>Management System</span>
                </div>
              </div>
            </li>
            <li class="detail grid-item">
              <div class="inner">
                <div
                  class="entry dizme_tm_portfolio_animation_wrap"
                  data-title="Admin Dashboard"
                  data-category="Detail"
                >
                  <a
                    class="portfolio_popup"
                    href="#"
                    @click.prevent="active = 6"
                  >
                    <img src="/img/portfolio/fac.png" alt="" />
                    <div
                      class="main"
                      data-img-url="/img/portfolio/fac.png"
                    ></div>
                  </a>
                </div>
                <div class="mobile_title">
                  <h3>Admin Dashboard</h3>
                  <span>Detail</span>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="brush_1 wow zoomIn" data-wow-duration="1s">
        <img src="/img/brushes/portfolio/1.png" alt="" />
      </div>
      <div class="brush_2 wow fadeInRight" data-wow-duration="1s">
        <img src="/img/brushes/portfolio/2.png" alt="" />
      </div>
    </div>
  </div>
  <MagnificPopUpVue
    :modal="modal"
    :close="closeModal"
    :name="name"
    :src="src"
  />
  <div :class="`${active === 1 ? '' : 'hidden_content'}`">
    <ModalBox :close="close">
      <div class="popup_details">
        <div class="top_image">
          <img src="img/thumbs/4-2.jpg" alt="" />
          <div
            class="main"
            data-img-url="img/portfolio/bitinner.png"
            style="background-image: url('/img/portfolio/bitinner.png')"
          ></div>
        </div>
        <div class="portfolio_main_title">
          <h3>Bitlogicx</h3>
          <span><a href="#">Dev & Tech</a></span>
          <div></div>
        </div>
        <div class="main_details">
          <div class="textbox">
            <p>
              Software To Transform Your Business Since 2019, Operating in
              Lahore, Pakistan.
            </p>
            <p>
              Welcome to Bitlogicx, your one-stop tech solutions provider for
              all industries. Since 2019, we have been delivering a
              comprehensive range of services including web development, mobile
              development, ERP, CMS and E-commerce solutions as well as graphic
              designing, UI/UX designing, content/technical writing, social
              media, and digital marketing. We have a good history with clients
              worldwide (especially from Europe and the Middle East) as we
              helped them reach their goals. Almost all of our clients are
              long-term since we have always provided them with our best work
              which is why they trust us & keep coming back for more work.
            </p>
          </div>
          <div class="detailbox">
            <ul>
              <li>
                <span class="first">Client</span>
                <span>Bitlogicx</span>
              </li>
              <li>
                <span class="first">Category</span>
                <span><a href="#">Tech Company</a></span>
              </li>
              <li>
                <span class="first">Date</span>
                <span>Sept 01, 2023</span>
              </li>
            </ul>
          </div>
        </div>
        <div class="additional_images">
          <ul>
            <li>
              <div class="list_inner">
                <div class="my_image">
                  <img src="img/thumbs/4-2.jpg" alt="" />
                  <div
                    class="main"
                    data-img-url="img/portfolio/bitinner2.png"
                    style="background-image: url('img/portfolio/bitinner2.png')"
                  ></div>
                </div>
              </div>
            </li>
            <li>
              <div class="list_inner">
                <div class="my_image">
                  <img src="img/thumbs/4-2.jpg" alt="" />
                  <div
                    class="main"
                    data-img-url="img/portfolio/bitinner3.png"
                    style="background-image: url('img/portfolio/bitinner3.png')"
                  ></div>
                </div>
              </div>
            </li>
            <li>
              <div class="list_inner">
                <div class="my_image">
                  <img src="img/thumbs/4-2.jpg" alt="" />
                  <div
                    class="main"
                    data-img-url="img/portfolio/bitinner4.png"
                    style="background-image: url('img/portfolio/bitinner4.png')"
                  ></div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </ModalBox>
  </div>
  <div :class="`${active === 2 ? '' : 'hidden_content'}`">
    <ModalBox :close="close">
      <div class="popup_details">
        <div class="top_image">
          <img src="img/thumbs/4-2.jpg" alt="" />
          <div
            class="main"
            data-img-url="img/portfolio/smp.png"
            style="background-image: url('img/portfolio/smp.png')"
          ></div>
        </div>
        <div class="portfolio_main_title">
          <h3>Location Based MultiVendor Service Provider</h3>
          <span><a href="#">Product</a></span>
          <div></div>
        </div>
        <div class="main_details">
          <div class="textbox">
            <p>
              Location-Based Multivendor Service Provider is a project focused
              on connecting users with nearby service professionals.
            </p>
            <p>
              With geolocation technology, users can easily find and hire local
              vendors for various services. The platform offers a user-friendly
              interface, real-time booking, secure payments, and a review system
              for a seamless experience. It aims to simplify the process of
              accessing local services while supporting and promoting local
              businesses.
            </p>
          </div>
          <div class="detailbox">
            <ul>
              <li>
                <span class="first">Client</span>
                <span>( Product )</span>
              </li>
              <li>
                <span class="first">Category</span>
                <span><a href="#">Product</a></span>
              </li>
              <li>
                <span class="first">Date</span>
                <span>March 22, 2023</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </ModalBox>
  </div>
  <div :class="`${active === 3 ? '' : 'hidden_content'}`">
    <ModalBox :close="close">
      <div class="popup_details">
        <div class="top_image">
          <img src="img/thumbs/4-2.jpg" alt="" />
          <div
            class="main"
            data-img-url="img/portfolio/ibninner.png"
            style="background-image: url('img/portfolio/ibninner.png')"
          ></div>
        </div>
        <div class="portfolio_main_title">
          <h3>IBN FIRNIS</h3>
          <span><a href="#">Website</a></span>
          <div></div>
        </div>
        <div class="main_details">
          <div class="textbox">
            <p>
              IBN FIRNAS, founded in 2018, is the sole distributor for DJI
              Drones in the Sultanate.
            </p>
            <p>
              We take on the responsibility to sow the seeds of progress in
              Oman. To that end, we provide you with all types of enterprise
              drones along with the necessary training, to support a variety of
              functionalities across various industries, including aerial
              photography, building inspection, agriculture surveillance and
              mapping, rescue operations, oil and gas refinery inspection and
              more
            </p>
          </div>
          <div class="detailbox">
            <ul>
              <li>
                <span class="first">Client</span>
                <span>IBN FIRNIS</span>
              </li>
              <li>
                <span class="first">Category</span>
                <span><a href="#">Drone Site</a></span>
              </li>
              <li>
                <span class="first">Date</span>
                <span>Nov 20, 2022</span>
              </li>
            </ul>
          </div>
        </div>
        <div class="additional_images">
          <ul>
            <li>
              <div class="list_inner">
                <div class="my_image">
                  <img src="img/portfolio/ibninner2.png" alt="" />
                  <div
                    class="main"
                    data-img-url="img/portfolio/ibninner2.png"
                    style="background-image: url('img/portfolio/ibninner2.png')"
                  ></div>
                </div>
              </div>
            </li>
            <li>
              <div class="list_inner">
                <div class="my_image">
                  <img src="img/portfolio/ibninner3.png" alt="" />
                  <div
                    class="main"
                    data-img-url="img/portfolio/ibninner3.png"
                    style="background-image: url('img/portfolio/ibninner3.png')"
                  ></div>
                </div>
              </div>
            </li>
            <li>
              <div class="list_inner">
                <div class="my_image">
                  <img src="img/portfolio/ibninner4.png" alt="" />
                  <div
                    class="main"
                    data-img-url="img/portfolio/ibninner4.png"
                    style="background-image: url('img/portfolio/ibninner4.png')"
                  ></div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </ModalBox>
  </div>
  <div :class="`${active === 4 ? '' : 'hidden_content'}`">
    <ModalBox :close="close">
      <div class="popup_details">
        <div class="top_image">
          <img src="img/portfolio/eyesinner.png" alt="" />
          <div
            class="main"
            data-img-url="img/portfolio/eyesinner.png"
            style="background-image: url('img/portfolio/eyesinner.png')"
          ></div>
        </div>
        <div class="portfolio_main_title">
          <h3>Eyes Of Hope</h3>
          <span><a href="#">Medical Service Provider</a></span>
          <div></div>
        </div>
        <div class="main_details">
          <div class="textbox">
            <p>
              Established on August 1, 2008, Eyes of Hope Home Health, LLC is a
              dedicated healthcare provider committed to creating a professional
              and caring environment for our patients in the comfort of their
              own homes.
            </p>
            <p>
              We are dedicated to in Home Care for patients. Eyes of Hope Home
              Health, LLC is CHAP Accredited which means we hold a higher
              standard than Medicare guidelines. We offer in Home Nursing,
              Social Workers, CNA Services, Physical, Occupational and Speech
              Therapy. We would glad to talk to you about what you qualify for.
            </p>
          </div>
          <div class="detailbox">
            <ul>
              <li>
                <span class="first">Client</span>
                <span>Regan</span>
              </li>
              <li>
                <span class="first">Category</span>
                <span><a href="#">Medical Service Provider</a></span>
              </li>
              <li>
                <span class="first">Date</span>
                <span>Oct 11, 2023</span>
              </li>
            </ul>
          </div>
        </div>
        <div class="additional_images">
          <ul>
            <li>
              <div class="list_inner">
                <div class="my_image">
                  <img src="img/portfolio/eyesinner2.png" alt="" />
                  <div
                    class="main"
                    data-img-url="img/portfolio/eyesinner2.png"
                    style="
                      background-image: url('img/portfolio/eyesinner2.png');
                    "
                  ></div>
                </div>
              </div>
            </li>
            <li>
              <div class="list_inner">
                <div class="my_image">
                  <img src="img/portfolio/eyesinner3.png" alt="" />
                  <div
                    class="main"
                    data-img-url="img/portfolio/eyesinner3.png"
                    style="
                      background-image: url('img/portfolio/eyesinner3.png');
                    "
                  ></div>
                </div>
              </div>
            </li>
            <li>
              <div class="list_inner">
                <div class="my_image">
                  <img src="img/portfolio/eyesinner4.png" alt="" />
                  <div
                    class="main"
                    data-img-url="img/portfolio/eyesinner4.png"
                    style="background-image: url('img/portfolio/eyesinner4.png')"
                  ></div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </ModalBox>
  </div>
  <div :class="`${active === 5 ? '' : 'hidden_content'}`">
    <ModalBox :close="close">
      <div class="popup_details">
        <div class="top_image">
          <img src="img/thumbs/4-2.jpg" alt="" />
          <div
            class="main"
            data-img-url="img/portfolio/battery.png"
            style="background-image: url('img/portfolio/battery.png')"
          ></div>
        </div>
        <div class="portfolio_main_title">
          <h3>Batteries Management</h3>
          <span><a href="#">Management System</a></span>
          <div></div>
        </div>
        <div class="main_details">
          <div class="textbox">
            <p>
              Our Battery Management System (BMS) optimizes lithium-ion battery
              performance with advanced monitoring, balancing, and safety
              features.
            </p>
            <p>
              This technology-driven system ensures reliable charging,
              discharging, and overall longevity, making it essential for
              various applications, from electric vehicles to renewable energy
              systems.
            </p>
          </div>
          <div class="detailbox">
            <ul>
              <li>
                <span class="first">Client</span>
                <span>Hassan</span>
              </li>
              <li>
                <span class="first">Category</span>
                <span><a href="#">Management System</a></span>
              </li>
              <li>
                <span class="first">Date</span>
                <span>Dec 22, 2023</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </ModalBox>
  </div>
  <div :class="`${active === 6 ? '' : 'hidden_content'}`">
    <ModalBox :close="close">
      <div class="popup_details">
        <div class="top_image">
          <img src="img/portfolio/img.png" alt="" />
          <div
            class="main"
            data-img-url="img/portfolio/img.png"
            style="background-image: url('img/portfolio/img.png')"
          ></div>
        </div>
        <div class="portfolio_main_title">
          <h3>Admin Dashboard</h3>
          <span><a href="#">Static</a></span>
          <div></div>
        </div>
        <div class="main_details">
          <div class="textbox">
            <p>
              Our Admin Dashboard project provides a centralized and intuitive
              interface for streamlined management and oversight.
            </p>
            <p>
              With real-time analytics, task tracking, and user management
              features, our dashboard enhances operational efficiency. It serves
              as a comprehensive tool for administrators to monitor, analyze,
              and make informed decisions, ultimately optimizing organizational
              processes.
            </p>
          </div>
          <div class="detailbox">
            <ul>
              <li>
                <span class="first">Client</span>
                <span>Savalina</span>
              </li>
              <li>
                <span class="first">Category</span>
                <span><a href="#">Detail</a></span>
              </li>
              <li>
                <span class="first">Date</span>
                <span>Jan 24, 2022</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </ModalBox>
  </div>
</template>

<script>
import MagnificPopUpVue from "./popup/MagnificPopUp.vue";
import ModalBox from "./popup/ModalBox.vue";
export default {
  name: "PortfolioComponent",
  data() {
    return {
      activeNav: "1", // props
      name: "",
      src: "",
      modal: false,
      // Content
      active: 0,
    };
  },
  mounted() {
    let Isotope = require("isotope-layout");
    setTimeout(() => {
      let iso = new Isotope(".gallery_zoom", {
        itemSelector: ".grid-item",
        //    layoutMode: "fitRows",
        percentPosition: true,
        masonry: {
          columnWidth: ".grid-item",
        },
        animationOptions: {
          duration: 750,
          easing: "linear",
          queue: false,
        },
      });
      let filterFns = {
        // show if number is greater than 50
        numberGreaterThan50: function (itemElem) {
          let number = itemElem.querySelector(".number").textContent;
          return parseInt(number, 10) > 50;
        },
        // show if name ends with -ium
        ium: function (itemElem) {
          let name = itemElem.querySelector(".name").textContent;
          return name.match(/ium$/);
        },
      };
      // bind filter button click filter main funtion
      let filtersElem = document.querySelector(".portfolio_filter ul");
      filtersElem.addEventListener("click", function (event) {
        if (event.target.getAttribute("data-filter")) {
          let filterValue = event.target.getAttribute("data-filter");
          filterValue = filterFns[filterValue] || filterValue;
          iso.arrange({ filter: filterValue });
        }
      });
    }, 1000);
  },
  methods: {
    onClick(name, src) {
      this.name = name;
      this.src = src;
      this.modal = true;
    },
    closeModal() {
      this.modal = false;
      this.name = "";
      this.src = "";
    },
    close() {
      this.active = 0;
    },
  },
  components: { MagnificPopUpVue, ModalBox },
};
</script>
