<template>
  <div class="dizme_tm_section" id="home">
    <div class="dizme_tm_hero">
      <div
        class="background"
        :data-img-url="`/img/slider/${dark ? 2 : 1}.jpg`"
      ></div>
      <div class="container">
        <div class="content">
          <div class="details">
            <div class="hello">
              <h3 class="orangeText">Hello, I'm</h3>
            </div>
            <div class="name">
              <h3>Ali Hamza</h3>
            </div>
            <div class="job">
              <p>
                A <span class="greenText">Software Engineer</span> From
                <span class="purpleText">Pakistan</span>
              </p>
            </div>
            <div class="text">
              <p>
                I am a certified Software Engineer and novice full-stack web
                developer and website designer, possessing robust creative and
                analytical abilities. Committed to innovation, adept at crafting
                and implementing solutions for a more promising future.
              </p>
            </div>
            <div class="button">
              <div class="dizme_tm_button">
                <a class="anchor" href="#about"><span>About Me</span></a>
              </div>
              <div class="social icon-he" >
                <ul>
                  <li>
                    <a href="https://github.com/alihamzazaka" target="_blank"
                      ><i class="icon-github-2"></i
                    ></a>
                  </li>

                  <li>
                    <a href="https://www.linkedin.com/in/ali-hamza-8b681a1a0/" target="_blank"
                      ><i class="icon-linkedin-1"></i
                    ></a>
                  </li>
                  <li>
                    <a
                      href="https://instagram.com/leagendary_photography" target="_blank"><i class="icon-instagram-1"></i
                    ></a>
                  </li>
                  <li>
                    <a href="https://wa.me/923070069000" target="_blank">
                      <i class="icon-whatsapp"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="avatar">
            <div class="image">
              <img src="/img/about/me.png" alt="" />
              <span
                class="skills illustrator anim_moveBottom"
                v-html="ai"
              ></span>
              <span class="skills photoshop anim_moveBottom" v-html="ps"></span>
              <span class="skills figma anim_moveBottom" v-html="figma"></span>
            </div>
          </div>
        </div>
      </div>
      <div class="dizme_tm_down">
        <a class="anchor" href="#process" v-html="dark ? mouseDark : mouse">
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { ai, figma, mouse, mouseDark, ps } from "../svg";
export default {
  name: "HomeComponent",
  data() {
    return { ai, ps, figma, mouse, mouseDark };
  },
  props: {
    dark: { type: Boolean },
  },
};
</script>
<style>
.icon-he{
  font-size: 26px;
}
</style>
