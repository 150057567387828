<template>
  <div class="dizme_tm_section">
    <div class="dizme_tm_skills">
      <div class="container">
        <div class="wrapper">
          <div class="left">
            <div
              class="dizme_tm_main_title wow fadeInUp"
              data-wow-duration="1s"
              data-align="left"
            >
              <span>Development & Deployment</span>
              <h3>I Develop Skills Regularly to Keep Me Update</h3>
              <p>
                Most common methods for designing & developing websites that work well on
                desktop has responsive design and good functionalities
              </p>
            </div>
            <div class="dodo_progress wow fadeInUp" data-wow-duration="1s">
              <div
                class="progress_inner skillsInner___"
                data-value="70"
                data-color="#950741"
              >
                <span
                  ><span class="label">Full-Stack Web Frameworks</span
                  ><span class="number">70%</span></span
                >
                <div class="background">
                  <div class="bar"><div class="bar_in"></div></div>
                </div>
              </div>
              <div
                class="progress_inner skillsInner___"
                data-value="60"
                data-color="#1cbe59"
              >
                <span
                  ><span class="label">DevOps</span
                  ><span class="number">60%</span></span
                >
                <div class="background">
                  <div class="bar"><div class="bar_in"></div></div>
                </div>
              </div>
              <div
                class="progress_inner skillsInner___"
                data-value="65"
                data-color="#8067f0"
              >
                <span
                  ><span class="label">Creative Designing</span
                  ><span class="number">65%</span></span
                >
                <div class="background">
                  <div class="bar"><div class="bar_in"></div></div>
                </div>
              </div>
            </div>
          </div>
          <div class="right">
            <img :src="`/img/skills/${dark ? 2 : 3}.jpg`" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SkillComponent",
  components: {},
  props: {
    dark: { type: Boolean },
  },
};
</script>
